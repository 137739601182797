import { Fragment } from "react";

import './footer.style.scss';

const Footer = () => {

    return (
        <Fragment>
            <br />
            <hr className="bottom-break" />
            <div className='footer'>
                Contact information: thelittlehouseofhobbies@gmail.com
            </div>
        </Fragment>

    );

};

export default Footer;