//import { async } from '@firebase/util';

import { useState } from 'react';
import { //createUserDocumentFromAuth,
    signInWithGooglePopup,
    signInUserWithEmailAndPassword
     } 
    from '../../utils/firebase/firebase.utils';
import FormInput  from '../form-input/form-input.component';

import './sign-in-form.style.scss';
import Button from '../button/button.component';

const defaultFormFields = {
    email: '',
    password: ''
}


const SignInForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email, password } = formFields;


    //console.log(formFields);

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    };

    const signInWithGoogle = async () => {
        try {
            await signInWithGooglePopup();
            
        }catch(error){
            if (error.code === 'auth/popup-closed-by-user'){
                console.log('User closed out of google pop up');
            }
            else{
                console.log(error.code);
            };
        };
        
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            signInUserWithEmailAndPassword(email,password);
            resetFormFields();
        } catch(error){
            
            switch(error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect password for email.');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email.');
                    break;
                default:
                    console.log(error);
            };
        };

    };

    const handleChange = (event) => {
        const {name, value} = event.target;

        setFormFields({...formFields, [name]: value })
    };

    return (
        <div className="sign-up-container">
            <h2>Already have an account?</h2>
            <span>Sign in with your email and password</span>
            <form onSubmit={handleSubmit}>

                <FormInput
                    label="Email"
                    type="email"
                    required
                    onChange={handleChange}
                    name="email"
                    value={email}/>

                <FormInput
                    label="Passwords"
                    type="password"
                    required 
                    onChange={handleChange}
                    name="password"
                    value={password}/>
                <div className='buttons-container'>
                    <Button type="submit">Sign In</Button>
                    <Button type='button' buttonType='google' onClick={signInWithGoogle}>
                        Google sign in
                    </Button>
                </div>
                
            </form>

        </div>
    );
};

export default SignInForm;