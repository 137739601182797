import { useContext } from 'react';
import { CartContext } from '../../context/cart.context';
import './product-card.style.scss'
import Button from '../button/button.component';

const ProductCard = ({product}) => {
  const {name, price, imageUrl} = product
  const { addItemToCart } = useContext(CartContext);
  const addProductToCart = () => addItemToCart(product)
  return(
    <div className='product-card-container'>
      <img src={imageUrl} alt={`${name}`} />
      <div className='footer'>
        <span className='name'>{name}</span>
        {/*<span className='price'>{price}</span> Taking out  price until it can be worked out*/}
      </div>
      {/* <Button buttonType='inverted' onClick={addProductToCart} >Add to cart</Button> Taking out until purchase can be tested*/} 
    </div>
  );
};

export default ProductCard