import { Link } from 'react-router-dom';
import ProductCard from '../product-card/product-card.component';
import './category-preview.style.scss';

const CategoryPreview = ({ title, products }) => {
  const categoryTitle = title

  return(
    <div className='category-preview-container'>
      <h2>
        <Link className='title' to={categoryTitle}>{categoryTitle.toUpperCase()}</Link>
      </h2>
      <div className='preview'>
        {
            products
              .filter((_, idx) => idx < 4)
              .map((product) =>
              <ProductCard  key={product.id} product={product} />)
        }
      </div>
    </div>  
  );
};

export default CategoryPreview