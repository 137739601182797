import './directory-item.style.scss';
import CategoryItem from '../category-item/category-item.component';


const DirectoryItem = ({ categories }) => {

    return (
      <div className='main_container'>
          <div className='slide_show'>
          </div>
          <div className='cards_container'>  
              {categories.map((category) => ( 
              <CategoryItem key={category.id} category={category}/>
              ))}
          </div>
      </div>
    );

};

export default DirectoryItem