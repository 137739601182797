import { Routes, Route } from 'react-router-dom';
import Home from "./routes/home/home.component";
import Store from "./routes/store/store.component";
import CheckOut from "./routes/checkout/checkout.component";
import Custom from "./components/custom-project-form/custom-project.component";
import Nav from './components/navigation/nav.component';
import Authentication from './routes/authentication/authentication.component';



const App = () => {

    return (
        <Routes>
            <Route path='/' element={<Nav />}>
                <Route index element={<Home />}/>
                <Route path='store/*' element={<Store />}/>
                <Route path='auth' element={<Authentication />}/>
                <Route path='custom' element={<Custom />}/>
                <Route path='checkout' element={<CheckOut />}/>
            </Route>
        </Routes>
    );

};

  

export default App;