import  Button  from '../button/button.component'
import './custom-project.styles.scss'

const CustomProject = () => {

    const material = [
        {
          id: 1,
          title: 'Vinyle',
          value: 'vinyle'
        },
        {
          id: 2,
          title: 'Wood',
          value: 'wood'
        },
        {
          id: 3,
          title: 'Metal',
          value: 'metal'
        },
        {
          id: 4,
          title: 'PLA',
          value: 'pla'
        }
    ]
    return(
        <div>
            <h1>Describe your custom project</h1>
            <form>
                <div className='selector'>
                    <select material="add_materail" id="material" required placeholder='Material'>
                    <option hidden>Select Material</option>
                        {material.map(({title, value, id}) => (
                            <option key={id} value={value}>{title}</option>
                            )
                        )}
                    </select>
                </div>
                <div>
                    <input type='text' placeholder='Hieght in inches'></input>
                </div>
                    <input type='text' placeholder='Width in inches'></input>
                <div>    
                    <textarea 
                        id="project_description"
                        name="project_description"
                        required
                        rows="5"
                        cols="50"
                        placeholder='Type description'>
                    </textarea>
                </div>
                    <input type="file" id="file1" name="upload"></input>
                <div>    
                    <Button type="submit" value="Submit">Submit</Button>
                </div>
            </form>

        </div>
    );
};

export default CustomProject