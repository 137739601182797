import { createContext, useState, useEffect } from "react";
import { getCategoriesAndDocuments } from '../utils/firebase/firebase.utils'
//import { addCollectionAndDocuments } from "../utils/firebase/firebase.utils"; //-uncomment to load new images

//import SHOP_DATA from '../shop-data.js'; //-uncomment to load new images
//import PRODUCTS from '../shop-data.json';  //using local json file remove to use firebase

export const CategoriesContext = createContext({
    categoriesMap: {},
});

export const CategoriesProvider = ({children}) => {
    //using local json file to use firebase change -> useState([])
    const [categoriesMap, setCategoriesMap] = useState({});  //setProducts was removed because it was unused.
    
    // This is how to import data into firebase
    //useEffect(() =>{
    //    addCollectionAndDocuments('categories', SHOP_DATA);
    //},[]);
    
    // Get Data from firestore
    useEffect(() => {
        const getCategoriesMap = async () => {
            const categoryMap = await getCategoriesAndDocuments();
            console.log(categoryMap);
            setCategoriesMap(categoryMap)
        }
        getCategoriesMap();
    }, [] );

    const value = {categoriesMap};
    return (
        <CategoriesContext.Provider value={value}> {children} </CategoriesContext.Provider>
    );

};