import { Outlet, Link } from 'react-router-dom';
import { Fragment} from 'react'; // when context is being used need to import useContext

//import { UserContext } from '../../context/user.context';
//import { CartContext } from '../../context/cart.context';

//import {signOutUser} from '../../utils/firebase/firebase.utils';
import Footer from '../footer/footer.component';

//import CartIcon from '../cart-icon/cart-icon.component';
//import CartDropDown from '../cart-dropdown/cart-dropdown.component';
import './nav.style.scss';




const Navigation = (props) => {
    //const {currentUser} = useContext(UserContext);
    //const {isCartOpen} = useContext(CartContext);
    
    return (
        <Fragment>
            <div className='navigation'>
                <Link className='logo_container' to='/'>
                    <div className='logo' />
                </Link>
                <div className='nav_links_container'>
                    <Link className='nav_links' to='/store'>
                        <div>STORE</div>
                    </Link>
                    {/*{currentUser ? (
                        <Link className='nav-links' to='/auth' onClick={signOutUser}>SIGN OUT</Link>
                        ) : (
                            <Link className='nav_links' to='/auth'>
                                <div>SIGN IN</div>
                            </Link>
                        )
                    } */}
                  {/*  <CartIcon /> Removing cart until purchase is proved working*/}
                </div>
                {/*{isCartOpen && <CartDropDown />} Removing cart until purchase is proved working*/}
                </div> 
            <Outlet />
            <Footer />
        </Fragment>
    );
};


export default Navigation;