import { Outlet } from 'react-router-dom';
import DirectoryItem from '../../components/directory/directory-item.component';



const Home = () => {
    const categories = [
        {
          id: 1,
          title: 'Clothing',
          image_url: '/pictures/peace_out_kidergarden.png',
        },
        {
          id: 2,
          title: 'Tumblers',
          image_url: '/pictures/tumbler_smoke.jpg',
        },
        {
          id: 3,
          title: 'Decor',
          image_url: '/pictures/cloud.png',
        },
        {
          id: 4,
          title: 'Woodwork',
          image_url: '/pictures/custom_box.png',
        },
        //{ Not ready yet
        //  id: 5,
        //  title: 'Custom',
        //  image_url: '/pictures/custom.png',
        //},
        {
          id: 5,
          title: 'Party Planning',
          image_url: '/pictures/guest_book.png',
        },
        {
          id: 6,
          title: 'Sports',
          image_url: '/pictures/volley_ball_bro.png',
        }
      ];

    return (
        <div>
            <Outlet />
            <DirectoryItem categories={categories}/>
        </div>
    );

};

  

export default Home;