import { initializeApp } from "firebase/app";
import { 
  getAuth,
  signInWithPopup,
  //signInWithRedirect,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
} from 'firebase/firestore';

const firebaseConfig = {

  apiKey: "AIzaSyBjxupP5ZOHowDlVkTKTiUcR1zGAEclR4M",
  authDomain: "hqcrafts-99093.firebaseapp.com",
  projectId: "hqcrafts-99093",
  storageBucket: "hqcrafts-99093.appspot.com",
  messagingSenderId: "68744866820",
  appId: "1:68744866820:web:e13b77bd8f7c6a9de65951"

};

//const app = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);

//const firebaseApp = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account"
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider)

export const db = getFirestore();

// add data to firestore
export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.title.toLowerCase());
    batch.set(docRef, object);
  });

  await batch.commit();
  console.log('done');

}

export const getCategoriesAndDocuments = async () => {
  const collectionRef = collection(db, 'categories');
  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);
  const categoryMap = querySnapshot.docs.reduce((acc, docSnapshot) => {
    const {title, items} = docSnapshot.data();
    acc[title.toLowerCase()] = items;
    return acc;
  }, {})

  return categoryMap;
}


export const createUserDocumentFromAuth = async (
  userAuth,
  additionalInformation = {}) => {
  if (!userAuth) return;

  const userDocRef = doc(db, 'users', userAuth.uid);
  console.log(userDocRef);
  const userSnapShot = await getDoc(userDocRef);
  console.log(userSnapShot.exists);
  console.log(userSnapShot.exists());

  if(!userSnapShot.exists()){
    const {displayName, email} = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef,
        {
          displayName,
          email,
          createdAt,
          ...additionalInformation
        })

    } catch(error){
      console.log('error creating user', error.message);
    }    
  }
  return userDocRef;
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if(!email || !password) return;

  return await   createUserWithEmailAndPassword(auth, email, password);
};

export const signInUserWithEmailAndPassword = async (email, password) => {
  if(!email || !password) return;

  return await   signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async() => await signOut(auth)

export const onAuthStateChangeListener = (callback) =>
{ 
  onAuthStateChanged(auth, callback )
}
