import { Link } from 'react-router-dom'
import './category-item.style.scss';


const CategoryItem = ({ category }) => {
    const {image_url, title } = category

    const categoryLinkHelper = (title) => {

      if (title === 'Custom') {
        return title.toLowerCase();
      }
      
      return 'store/'+ title.toLowerCase();
      
        // //`store/${title.toLowerCase()
      
    }
    
    return (
        <div className='cards'>
            <div 
              className='background-image'
              style={{
                backgroundImage: `url(${image_url})`,
              }} 
            />
            <Link className='category-body-container' to={categoryLinkHelper(title)}>
              <div>
                <h2>{title}</h2>
              </div>
            </Link> 
        </div> 

    );

};

export default CategoryItem



